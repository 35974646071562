import Fingerprint2 from "fingerprintjs2";
import _ from "lodash";
import md5 from "blueimp-md5";
import { getService } from "@/service";

export interface UploadComponent {
  data?: string;
  lastId?: string | null;
  key?: string;
  value?: unknown;
}

/**
 * 当前是代理域名时
 * 下载完成之后需要上传设备信息
 */
export async function uploadFinger(url: string) {
  try {
    const { components, hash } = await fingerprintReport();
    const formatUrl = url.replace("http://www", "m");
    components.data = formatUrl;
    const pid = "zz";
    const response = await getService().global.uploadFingerReport(
      components,
      hash,
      pid
    );
    if (response.data) {
      console.log("上传成功");
    } else {
      throw new Error("上传指纹信息失败");
    }
  } catch (error) {
    throw new Error("获取指纹信息失败");
  }
}

/**
 * 获取代理下载完上传时需要的信息
 */
async function fingerprintReport() {
  const components = await Fingerprint2.getPromise({
    excludes: { enumerateDevices: true, doNotTrack: true, fontsFlash: true },
  });
  let newComponents: UploadComponent = {
    data: window.location.host,
    lastId: "",
  };
  _.forEach(_.keys(components), (index) => {
    const obj = components[index as any];
    let value = JSON.stringify(obj.value);
    if (
      (obj.key === "webgl" ||
        obj.key === "plugins" ||
        obj.key === "fonts" ||
        obj.key === "canvas") &&
      value.length > 200
    ) {
      value = md5(value);
    }
    newComponents = Object.assign(newComponents, { [obj.key]: value });
  });
  return {
    components: newComponents,
    hash: Fingerprint2.x64hash128(
      components.map((pair) => pair.value).join(),
      31
    ),
  };
}
