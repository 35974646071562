
import { Vue } from "vue-class-component";
import { util } from "dev-sdk";
import i18n from "@/lang";
const locale = i18n.global.locale.value;
import { getService } from "@/service";
import { uploadFinger } from "@/utils/download-app";

export default class Download extends Vue {
  $copyText: any;

  private android = "";
  private ios = "";
  private h5 = "";

  async created() {
    await this.getDownload();
  }

  async getDownload() {
    const data = await getService().global.downloadUrl();
    this.android = data.android;
    this.ios = data.ios;
    this.h5 = data.h5;
  }

  async install() {
    await this.$copyText(`((proxy=${this.h5.replace("http://www", "m")}))`);
    await uploadFinger(this.h5);
    if (util.OS.iPhone) {
      const link = `itms-services://?action=download-manifest&url=${this.ios}`;
      window.location.href = link;
      return;
    }
    window.location.href = this.android;
  }

  goH5() {
    window.location.href = this.h5;
  }

  render() {
    return (
      <div class="download">
        <div class="content-view">
          <img class="bg" src={require(`./assets/${locale}/bg.png`)} />
          <div class="content">
            <div class="btn download-btn" onClick={this.install}>
              {this.$t("download.install")}
            </div>
            <div
              {...{
                class: `btn go-btn ${locale === "vi" ? "vi-font" : ""}`,
              }}
              onClick={this.goH5}
            >
              {this.$t("download.goH5")}
            </div>
            {util.OS.iPhone ? (
              <div class="tutorial">{this.$t("download.tutorial")}</div>
            ) : null}
          </div>
        </div>
        {util.OS.iPhone ? (
          <div>
            <img class="expansion" src={require("./assets/expansion.png")} />
            <div class="title-content">
              <div class="title">{this.$t("download.iosTitle")}</div>
              <div class="subTitle">{this.$t("download.subTitle")}</div>
            </div>
            <img
              class="ios-tutorial"
              src={require(`./assets/${locale}/tutorial.png`)}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
